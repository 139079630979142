import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import { Link } from "gatsby";
import Seo from "../components/seo";
import LinkButton from "../components/link-button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Seo title="What is Longtermism?" mdxType="Seo" />
    <div className='highlight'>
Longtermism is the view that we should be doing much more to protect future generations.
    </div>
    <p>{`Three ideas come together to suggest this view. First, future people matter. Our lives surely matter just as much as those lived thousands of years ago — so why shouldn’t the lives of people living thousands of years hence matter equally? Second, the future could be vast. Absent catastrophe, most people who will ever live have not yet been born. Third, our actions may predictably influence how well this long-term future goes. In sum, it may be our responsibility to ensure future generations get to survive and flourish.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.bbc.com/future/article/20220805-what-is-longtermism-and-why-does-it-matter"
      }}>{`Longtermism`}</a>{` is not a single, fully worked-out perspective. Instead, it's a family of views related by a shared recognition of the importance of safeguarding and improving humanity’s long-term prospects. Because so much remains to be learned about achieving that goal, longtermism is also a kind of paradigm for `}<em parentName="p">{`research`}</em>{` aimed at improving the world — spanning philosophy, economics, law, even AI.`}</p>
    <p>{`Of course, concern for the long-run future is not a new idea. But a growing number of researchers, advocates, entrepreneurs, and policymakers are now choosing to be guided by this perspective, and are beginning to think seriously about what it implies.`}</p>
    <p>{`This website aims to be a useful resource for learning about different aspects of longtermism. If you're looking for a slightly longer introduction to the key ideas, click below. It's about a 10-minute read.`}</p>
    <LinkButton to='/introduction' mdxType="LinkButton">Continue reading: <b>an introduction to longtermism</b></LinkButton>
    <p>{`The `}<Link to='/faq' mdxType="Link">{`FAQ`}</Link>{` page tries answering common questions about longtermism, and considers some objections. For instance, why think that acting to improve the far future should be a key priority, when there are already so many immediate problems today?`}</p>
    <LinkButton to='/faq' mdxType="LinkButton">Continue reading: <b>a longtermism FAQ</b></LinkButton>
    <p>{`The `}<Link to='/resources' mdxType="Link">{`resources`}</Link>{` page gives an overview of places to jump off from this website and learn more about some aspect of longtermist thought, including books, articles, interviews, and talks.`}</p>
    <LinkButton to='/resources' mdxType="LinkButton">Continue reading: <b>resources for learning more</b></LinkButton>
    <p>{`Finally, if you're interested in acting on these ideas, the `}<Link to='/get-involved' mdxType="Link">{`'get involved'`}</Link>{` page offers some suggestions.`}</p>
    <LinkButton to='/get-involved' mdxType="LinkButton">Continue reading: <b>how to get involved</b></LinkButton>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      